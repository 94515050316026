import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import NextLink from 'next/link';
import { oneLine } from 'common-tags';

import { Button, Caption, Link } from '@/atoms';

import type { TypeTextColorVariant } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeNewsletterProps } from './types';

const VARIANT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: 'text-primary-400',
  secondary: 'text-secondary-400',
  alternative: 'text-white',
};

const INPUT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: 'border-primary-400 placeholder-primary-400',
  secondary: 'border-secondary-400 placeholder-secondary-400',
  alternative: 'border-white placeholder-white',
};

/**
 * Newsletter
 */
export const Newsletter: FunctionComponent<TypeNewsletterProps> = ({
  className = '',
  caption,
  desc,
  image,
  title,
  backgroundColor,
  legalResume,
  linkText,
  linkUri,
  textColor,
}: TypeNewsletterProps) => {
  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState('');
  const { t } = useTranslation('common');
  const handlerEmail = (e: any) => {
    const reg = new RegExp(
      /^(?=[A-Z0-9@._%+-]{6,254}$)[A-Z0-9._%+-]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,8}[A-Z]{2,63}$/i
    );

    setEmail(e.target.value);
    if (reg.test(e.target.value)) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  return (
    <div
      className={`flex flex-col lg:flex-row ${VARIANT_MAPS[textColor]} ${className}`}
    >
      <div className="relative h-36 w-full md:h-56 lg:h-auto lg:w-1/2">
        {image && (
          <Image
            src={image}
            layout="fill"
            objectFit="cover"
            alt="image NewsLetter"
          />
        )}
      </div>
      <form
        method="POST"
        id="zcampaignOptinForm"
        action={process.env.NEXT_PUBLIC_NEWSLETTER_SUBSCRIPTION_ID}
        target="_zcSignup"
        style={{
          backgroundColor: `var(--tw-color-${backgroundColor})`,
        }}
        className="flex flex-col gap-3 px-5  py-10 md:gap-5 md:p-12 md:pr-[max(1.25rem,_calc((100%_-_var(--wrapper-size)_+_2.5rem)_/_2))] lg:w-1/2"
      >
        {caption && <Caption variant={textColor}>{caption}</Caption>}
        <h2
          className={oneLine`
            text-heading text-heading-mobile--h2 lg:text-heading--h2
            ${caption ? 'md:mt-4' : ''}
          `}
        >
          {title}
        </h2>
        <p className="text-heading-mobile--h5 text-heading mt-2 lg:text-heading--h5 md:mt-0">
          {desc}
        </p>
        <label className="mt-8 flex flex-col gap-3 md:mt-5">
          <span className="text-other text-other--label-form">EMAIL:</span>
          <input
            style={{
              backgroundColor: `var(--tw-color-${backgroundColor})`,
            }}
            onChange={(e) => handlerEmail(e)}
            className={` border-b ${INPUT_MAPS[textColor]} border-l-0 border-r-0 border-t-0  outline-none placeholder:opacity-60`}
            type="email"
            placeholder={t('components.templates.newsLetter.placeholder')}
            id="EMBED_FORM_EMAIL_LABEL"
            value={email}
            name="CONTACT_EMAIL"
          />
        </label>
        <div className="w-14">
          <Button
            as="button"
            type="submit"
            disabled={!valid}
            size="large"
            variant="secondary"
          >
            {t('components.templates.newsLetter.send')}
          </Button>
        </div>
        {/* eslint-disable-next-line */}
        {/* @ts-ignore */}
        <input type="hidden" id="fieldBorder" value="" />
        <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="" />
        <input type="hidden" name="viewFrom" id="viewFrom" value="URL_ACTION" />
        <input
          type="hidden"
          id="submitType"
          name="submitType"
          value="optinForm_save"
        />
        <input type="hidden" id="lD" name="lD" value="" />
        <input type="hidden" name="emailReportId" id="emailReportId" value="" />
        <input
          type="hidden"
          id="listId"
          name="listId"
          value={process.env.NEXT_PUBLIC_NEWSLETTER_LIST_ID}
        />
        <input
          type="hidden"
          id="mandatoryListId"
          name="mandatoryListId"
          value={process.env.NEXT_PUBLIC_NEWSLETTER_LIST_ID}
        />
        <input type="hidden" name="zx" id="cmpZuid" value="14ad9be886" />
        <input type="hidden" name="zcvers" value="2.0" />
        <input
          type="hidden"
          name="oldListIds"
          id="allCheckedListIds"
          value=""
        />
        <input type="hidden" id="mode" name="mode" value="OptinCreateView" />
        <input type="hidden" id="zcld" name="zcld" value="" />
        <input type="hidden" id="zctd" name="zctd" value="" />
        <input type="hidden" id="document_domain" value="" />
        <input type="hidden" id="zc_Url" value="zcv3-zcmp.maillist-manage.eu" />
        <input type="hidden" id="new_optin_response_in" value="0" />
        <input type="hidden" id="duplicate_optin_response_in" value="0" />
        <input type="hidden" id="zc_formIx" name="zc_formIx" value="" />
        <input
          type="hidden"
          id="zc_formIx"
          name="zc_formIx"
          value={process.env.NEXT_PUBLIC_NEWLETTER_FORM_ID}
        />
        <input type="hidden" id="viewFrom" value="URL_ACTION" />
        <input type="hidden" id="scriptless" name="scriptless" value="yes" />
        <div className="text-other text-other--detail">
          {legalResume && (
            <div
              className="[&>a]:font-bold [&>a]:text-tertiary-400"
              dangerouslySetInnerHTML={{ __html: legalResume }}
            />
          )}

          {linkUri && linkText && (
            <NextLink passHref prefetch={false} href={linkUri}>
              <Link
                className="!text-other !text-other--detail !inline w-fit"
                variant={textColor}
              >
                {linkText}
              </Link>
            </NextLink>
          )}
        </div>
      </form>
    </div>
  );
};

Newsletter.displayName = 'Newsletter';
